import React, { useRef, useEffect } from "react";
import desktopImage from "../assets/MainVideo.gif"; // Make sure to import your image here
import entryVideo from "../assets/videos/MainVideo.mp4";
import mobileVideo from "../assets/MobileVideo.mp4";
import mobilePhoto from "../assets/MobilePhoto.svg";
import mobileGIF from "../assets/MobileVideo.gif";

const FullPageImage = () => {
  const style = {
    height: "100vh", // Viewport height: make it cover the full page
    width: "100vw", // Viewport width: make it cover the full width
    //background: `url(${backgroundImage}) no-repeat center center fixed`,
    //backgroundSize: "", // Cover the entire viewport without stretching
    margin: 0, // Remove default margin
    padding: 0, // Remove default padding
  };

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .catch((error) => console.log("Video play failed:", error));
    }
  }, []);

  const videoStyles = {
    width: "100%",
    height: "auto",
    position: "fixed",
    display: "block", // Ensure the video is visible by default
    // Use a media query to hide the video on screens wider than 600px
    "@media (min-width: 601px)": {
      display: "none",
    },
  };

  return (
    <>
      <div style={style}>
        <picture>
          <source media="(max-width: 600px)" srcSet={mobileGIF} />
          <img
            src={desktopImage}
            style={{ position: "fixed", width: "100%" }}
          ></img>
        </picture>

        {/* <video ref={videoRef} style={videoStyles} autoPlay loop muted>
          <source src={mobileVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
      </div>
      ;
    </>
  );
};

export default FullPageImage;
