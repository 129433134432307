import React, { useState } from "react";
import {
  Container,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Button,
  Box,
  Typography,
  Paper,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import WeddingInvitation from "./WeddingInvitation";
import Countdown from "./CountDown";
import ScheduleTimeline from "./ScheduleTimeline";
import RSVPQuestion from "./RSVPQuestion";
import DressCode from "./DressCode";
import Gifts from "./Gifts";
import FadeUpComponent from "./FadeUpComponent";
import FAQ from "./FAQ";

const RSVPPage = () => {
  return (
    <div
      style={{
        position: "relative",
        zIndex: 5,
        backgroundColor: "white",
        padding: 5,
      }}
    >
      <WeddingInvitation />
      <FadeUpComponent>
        <ScheduleTimeline />
      </FadeUpComponent>
      <FadeUpComponent>
        <DressCode />
      </FadeUpComponent>
      <FadeUpComponent>
        <Countdown />
      </FadeUpComponent>
      <FadeUpComponent>
        <FAQ />
      </FadeUpComponent>
      <FadeUpComponent>
        <RSVPQuestion />
      </FadeUpComponent>
      <FadeUpComponent>
        <Gifts />
      </FadeUpComponent>
      {/* 
      
      
       */}
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </div>
  );
};

export default RSVPPage;
