import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  TextField,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";

const RSVPQuestion = () => {
  const [accepted, setAccepted] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);

  const url = "https://api.brevo.com/v3/smtp/email";
  const apiKey =
    "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"; // Replace with your actual API key

  const handleDecline = () => {
    handleDialogClose();
    setDeclineDialogOpen(true);
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    // Allow only digits to be entered
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      setPhone(value);
    }
  };

  const handleAccept = () => {
    setAccepted(true);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    // Resetting input fields
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");

    // Resetting error states
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setPhoneError(false);

    // Closing the dialog
    setDialogOpen(false);
  };

  const sendSMS = () => {};

  const handleFormSubmit = async () => {
    //Reset validation states
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
    setPhoneError(false);

    let isValid = true;

    if (!firstName) {
      setFirstNameError(true);
      isValid = false;
    }
    if (!lastName) {
      setLastNameError(true);
      isValid = false;
    }
    if (!email) {
      setEmailError(true);
      isValid = false;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        console.log("Not valid");
        setEmailError(true);
        isValid = false;
      } else {
        console.log("Valid");
      }
    }
    if (!phone) {
      setPhoneError(true);
      isValid = false;
    } else {
      // Phone validation
      const phoneRegex = /^(\+63|0)\d{10,11}$/; // Adjust based on your expected phone format
      if (!phoneRegex.test(phone)) {
        setPhoneError(true);
        isValid = false;
      }
    }

    // //Validate
    // if (!isValid) {
    //   alert("Please fill in all required fields correctly.");
    //   return;
    // }

    // Submit the form data
    console.log(
      `First Name: ${firstName}, Last Name: ${lastName}, Email: ${email}, Phone: ${phone}`
    );

    if (isValid) {
      // Close the dialog after submitting the form
      const emailData = {
        sender: {
          name: "RJ and Lara Wedding RSVP",
          email: "rsvp@robertandlarawedding.com",
        },
        to: [
          {
            email: email,
            name: firstName + " " + lastName,
          },
        ],
        cc: [
          {
            email: "rsvp@robertandlarawedding.com", // The CC email address
            name: "RSVP", // The name for the CC email
          },
        ],
        subject: "Your RSVP for Robert and Lara Wedding is Confirmed!",
        htmlContent: `
  <!DOCTYPE html>
  <html>
  <head>
      <title>RSVP Confirmation</title>
      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 20px;
              color: #333;
          }
          .container {
              max-width: 600px;
              margin: auto;
              background-color: #f8f8f8;
              padding: 20px;
              border-radius: 5px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          .header {
              font-size: 24px;
              color: #444;
          }
          .content {
              font-size: 16px;
              line-height: 1.6;
          }
          .footer {
              margin-top: 20px;
              font-size: 14px;
              text-align: center;
              color: #aaa;
          }
      </style>
  </head>
  <body>
      <div class="container">
          <div class="header">
              Your RSVP for Robert and Lara Wedding is Confirmed!
          </div>
          <div class="content">
              <p>Dear ${firstName} ${lastName},</p>
              <p>We are delighted to inform you that your RSVP for the wedding on June 15, 2024 has been approved! We are eagerly looking forward to your participation and are excited to share this occasion with you.</p>
              <p>To ensure the best experience for all our guests, please let us know if there are any changes to your availability or if you have any special requests by May 30, 2024.</p>
              <p>Should you have any questions or need further assistance, feel free to contact us at 09175041217.</p>
              <p>Thank you for your prompt RSVP. We're looking forward to creating wonderful memories together!</p>
              <p>Warmest regards,</p>
              <p>Robert and Lara</p>
          </div>
          <div class="footer">
              This is an automated message. Please do not reply directly to this email.
          </div>
      </div>
  </body>
  </html>
  `,
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            accept: "application/json",
            "api-key": apiKey,
            "content-type": "application/json",
          },
          body: JSON.stringify(emailData),
        });

        if (!response.ok) throw new Error("Email sending failed");

        sendSMS();

        const data = await response.json();

        handleDialogClose();
        setSuccessDialogOpen(true);

        console.log(data);
      } catch (error) {
        console.error("Error:", error);
      }

      handleDialogClose();
    }
  };

  return (
    <div
      style={{ textAlign: "center", marginTop: "70px", marginBottom: "20px" }}
    >
      <Typography
        style={{
          fontSize: "50px",
          textAlign: "center",
          margin: "auto",
          paddingBottom: "10px",
          fontFamily: "EnternalLight",
          fontStyle: "Italic",
          lineHeight: "1",
        }}
        color="#5A86AD"
      >
        Will you attend our wedding?
      </Typography>
      <div style={{ marginTop: "20px" }}>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="outlined"
            startIcon={<CheckIcon />}
            sx={{
              width: "120px",
              height: "50px",
              "&:hover": {
                bgcolor: "green", // Green background on hover
                color: "white", // White text on hover
              },
            }}
            onClick={handleAccept}
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            startIcon={<CloseIcon />}
            sx={{
              width: "120px",
              height: "50px",
              "&:hover": {
                bgcolor: "red", // Red background on hover for Decline button
                color: "white", // White text on hover for Decline button
              },
            }}
            onClick={handleDecline}
          >
            Decline
          </Button>
        </Stack>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>
            <Typography
              component="h2"
              variant="h6"
              style={{ textAlign: "center" }}
            >
              Your contact information
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body2"
              style={{ marginBottom: "20px", textAlign: "center" }}
            >
              Please provide your contact information (will only be visible to
              the organizer).
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="first-name"
              label="First Name"
              type="text"
              fullWidth
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              error={firstNameError}
              helperText={firstNameError ? "First name is required" : ""}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="dense"
              fullWidth
              id="last-name"
              label="Last Name"
              defaultValue="Last Name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              InputLabelProps={{
                shrink: true,
              }}
              error={lastNameError}
              helperText={lastNameError ? "Last name is required" : ""}
            />
            <TextField
              margin="dense"
              id="email"
              label="E-mail address"
              type="email"
              fullWidth
              defaultValue="E-mail address"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              InputLabelProps={{
                shrink: true,
              }}
              error={emailError}
              helperText={
                emailError ? "Please enter a valid email address" : ""
              }
            />
            <TextField
              margin="dense"
              id="phone"
              label="Mobile phone"
              type="tel"
              fullWidth
              defaultValue="Mobile phone"
              variant="outlined"
              value={phone}
              onChange={handlePhoneChange}
              inputProps={{
                pattern: "[0-9]*",
                "aria-required": "true",
                "aria-label": "Mobile number Required",
              }}
              required
              InputLabelProps={{
                shrink: true,
              }}
              error={phoneError}
              helperText={phoneError ? "Please enter a valid phone number" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Box width="100%" display="flex" justifyContent="center" p={1}>
              <Button
                onClick={handleFormSubmit}
                color="primary"
                variant="contained"
                endIcon={<SendIcon />}
              >
                Submit RSVP
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Dialog
          open={successDialogOpen}
          onClose={() => setSuccessDialogOpen(false)}
        >
          <DialogTitle>RSVP Submitted Successfully!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Thank you for your response. We look forward to seeing you at the
              event!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSuccessDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={declineDialogOpen}
          onClose={() => setDeclineDialogOpen(false)}
          aria-labelledby="decline-dialog-title"
          aria-describedby="decline-dialog-description"
        >
          <DialogTitle id="decline-dialog-title">{"RSVP Declined"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="decline-dialog-description">
              We're sorry to hear you can't make it to our event. If your plans
              change, let us know – we'd love to have you there. Thank you for
              letting us know.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeclineDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default RSVPQuestion;
