import React from "react";
import { useInView } from "react-intersection-observer";

const FadeRightComponent = ({ children, onAnimationEnd }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Adjust this value as needed
  });

  const defaultStyle = {
    opacity: 0,
    transform: "translateX(20px)", // Slide from the right
    transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
  };

  const activeStyle = {
    opacity: 1,
    transform: "translateX(0)",
  };

  return (
    <div
      ref={ref}
      style={inView ? { ...defaultStyle, ...activeStyle } : defaultStyle}
      onTransitionEnd={inView ? onAnimationEnd : null} // Only call onAnimationEnd when inView becomes true
    >
      {children}
    </div>
  );
};

export default FadeRightComponent;
