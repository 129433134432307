import React from "react";
import { useInView } from "react-intersection-observer";

const FadeLeftComponent = ({ children, onAnimationEnd }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? "translateX(0)" : "translateX(-20px)",
        transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
      }}
      onTransitionEnd={inView ? onAnimationEnd : null} // Only call onAnimationEnd when inView becomes true
    >
      {children}
    </div>
  );
};

export default FadeLeftComponent;
