import React, { useEffect, useState } from 'react'
import '../css/StickyHeader.css';
import HeaderGrid from './HeaderGrid';

const Header = () => {
    const [isVisible, setIsVisible] = useState(true);
    const scrollThreshold = 200; // Set a scroll threshold
  
    useEffect(() => {
      let lastScrollY = 0;
  
      const handleScroll = () => {
        const currentScrollY = window.scrollY;
  
        if (currentScrollY > scrollThreshold) {
          // If scrolling down and component is visible, hide it
          if (currentScrollY > lastScrollY && isVisible) {
            setIsVisible(false);
          }
        } else {
          // If near the top, ensure the component is visible
          setIsVisible(true);
        }
  
        lastScrollY = currentScrollY;
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [isVisible]);
  
    // Use inline styles for animation
    const style = {
      transition: 'opacity 0.5s ease-in-out',
      opacity: isVisible ? 1 : 0,
      display: isVisible ? 'block' : 'none', // Immediately hide the element to prevent it from taking up space when not visible
    };

    return (
        <header className={'header sticky '} style={style}>
            <HeaderGrid />
        </header>
    );
}

export default Header;