import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import weddingPhoto from "../assets/image2.jpg";
import weddingPhoto2 from "../assets/image3.jpg";
import weddingPhoto3 from "../assets/image4.jpg";
import weddingPhoto4 from "../assets/image5.jpg";
import weddingPhoto5 from "../assets/image6.jpg";
import weddingPhoto6 from "../assets/image7.jpg";
import weddingPhoto7 from "../assets/image8.jpg";
import weddingPhoto8 from "../assets/image9.jpg";
import weddingPhoto9 from "../assets/image10.jpg";
import weddingPhoto10 from "../assets/image11.jpg";
import weddingPhoto11 from "../assets/image12.jpg";
import weddingPhoto12 from "../assets/image13.jpg";
import weddingPhoto13 from "../assets/image14.jpg";
import weddingPhoto14 from "../assets/image15.jpg";
import weddingPhoto15 from "../assets/image16.jpg";
import weddingPhoto16 from "../assets/image17.jpg";
import weddingPhoto17 from "../assets/image18.jpg";
import weddingPhoto18 from "../assets/image19.jpg";
import weddingPhoto19 from "../assets/image20.jpg";

import Slideshow from "./Slideshow";

const WeddingInvitation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const slides = [
    weddingPhoto,
    weddingPhoto2,
    weddingPhoto3,
    weddingPhoto4,
    weddingPhoto5,
    weddingPhoto6,
    weddingPhoto7,
    weddingPhoto8,
    weddingPhoto9,
    weddingPhoto10,
    weddingPhoto11,
    weddingPhoto12,
    weddingPhoto13,
    weddingPhoto14,
    weddingPhoto15,
    weddingPhoto16,
    weddingPhoto17,
    weddingPhoto18,
    weddingPhoto19,
  ];

  return (
    <Card sx={{ maxWidth: isMobile ? 345 : 600, margin: "auto", mt: 2 }}>
      <Slideshow slides={slides} interval={5000} />
      {/* <CardMedia
        component="img"
        height="194"
        image={weddingPhoto} // Replace with your image path
        alt="Couple embracing"
        sx={{ width: "100%", height: "auto" }}
      /> */}
      <CardContent>
        <Typography
          gutterBottom
          component="div"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "20px",
            lineHeight: "1",
            fontStyle: "Italic",
          }}
        >
          We’re tying the knot and we’d love for you to be a part of our special
          day. Your presence will be the greatest gift, as we embark on this
          beautiful journey together.
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mt: 5,
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "20px",
            fontStyle: "Italic",
            lineHeight: "1",
          }}
        >
          Please let us know if you can make it!
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            my: 2,
            textAlign: "center",
            fontWeight: "Bold",
            fontSize: "35px",
          }}
        >
          ❤️
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            my: 2,
            textAlign: "center",
            fontFamily: "EnternalLight",
            fontSize: "40px",
            fontWeight: "Bold",
            fontStyle: "Italic",
            color: "#5A86AD",
          }}
        >
          RJ & Lara
        </Typography>
      </CardContent>
    </Card>
  );
};

export default WeddingInvitation;
