import React, { useState, useEffect } from "react";
import "../css/Slideshow.css";

const Slideshow = ({ slides, interval = 5000 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setFade(false); // Remove the fade-in class to reset the state
      setTimeout(() => {
        setCurrentSlide((currentSlide) => (currentSlide + 1) % slides.length);
        setFade(true); // Reapply the fade-in class to trigger the animation
      }, 100); // A short timeout to ensure the class is toggled effectively
    }, interval);

    return () => clearInterval(slideInterval);
  }, [slides.length, interval]);

  // Ensure the fade-in effect is applied on the initial render
  useEffect(() => {
    setFade(true);
  }, []);

  return (
    <div className="slideshow">
      <img
        src={slides[currentSlide]}
        alt={`Slide ${currentSlide}`}
        className={fade ? "fade-in" : ""}
        width="100%"
      />
    </div>
  );
};

export default Slideshow;
