import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Link from "@mui/material/Link";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import "../css/HeaderGrid.css";
import Button from "@mui/material/Button";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "transparent",
  boxShadow: "none",
  height: "100%", // Ensure each item takes full height
  display: "flex",
  flexDirection: "column",
  justifyContent: "Top", // Center children vertically
  alignItems: "center", // Center children horizontally
}));

const style = {
  padding: "0px",
};

const HeaderGrid = () => {
  const handleViewMap = () => {
    // Implement the logic to view the map, for example open a new tab with the map URL
    const mapUrl =
      "https://www.google.com/maps/place/St.+Bonaventure+Parish+Church+-+Daungan,+Mauban,+Quezon+(Diocese+of+Lucena)/@14.1904668,121.7283145,17z/data=!3m1!4b1!4m6!3m5!1s0x339807480a2a6e1f:0xefda785b3f79b03!8m2!3d14.1904668!4d121.7308948!16s%2Fg%2F11b6xk36ph?entry=ttu";
    window.location.href = mapUrl;
  };

  return (
    <Grid
      container
      spacing={0}
      alignItems="stretch"
      style={{ maxWidth: "800px", margin: "auto" }}
    >
      <Grid item xs={4}>
        <Item>
          <h3 className="topHeader">Date & Time</h3>
          <p className="secondLabel">June 15, 2024 10:00 am</p>
          <Link
            color="#5A86AD"
            fontSize={"12px"}
            fontWeight={"700"}
            textAlign={"center"}
          >
            Add to Calendar
          </Link>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <h3 className="topHeader">Location</h3>
          <p className="secondLabel">
            St. Bonaventure Parish Church, Mauban, Quezon
          </p>
          <Link
            href="#"
            onClick={handleViewMap}
            color="#5A86AD"
            fontSize={"12px"}
            fontWeight={"700"}
            textAlign={"center"}
          >
            View map
          </Link>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <h3 className="topHeader">Contact</h3>
          <p className="secondLabel">Reach out to us anytime at 09175041217</p>
        </Item>
      </Grid>
    </Grid>
  );
};

export default HeaderGrid;
