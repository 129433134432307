// DownArrow.js
import React from "react";
import "../css/DownArrow.css"; // Importing the CSS file for styles

const DownArrow = () => {
  return (
    <div className="down-arrow-container">
      <svg
        className="down-arrow"
        width="70"
        height="70"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#34d2eb"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6 9l6 6 6-6" />
      </svg>
    </div>
  );
};

export default DownArrow;
