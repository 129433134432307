import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import UserMenu from './components/UserMenu';
import RSVPPage from './components/RSVPPage';
import FullPageImage from './components/FullPageImage';
import DownArrow from './components/DownArrow';
import RSVPButton from './components/RSVPButton';
import CenteredScreen from './components/Centered';
import Header from './components/Header';

function MyApp() {

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div id="back-to-top-anchor" />
      <Header />
      <FullPageImage />
      <DownArrow />
      <RSVPPage />     
      <CenteredScreen>
        <RSVPButton />
      </CenteredScreen>
    </div>
  );
}

export default MyApp;