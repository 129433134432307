import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import PrincipalPhoto from "../assets/PricipalSponsors.png";
import PrincipalSponsorsColors from "../assets/PrincipalSponsorsColors2.png";
import GuestColors from "../assets/GuestColor.png";
import GuestDress from "../assets/GuestDress.png";
import FadeUpComponent from "./FadeUpComponent";
import FadeLeftComponent from "./FadeLeftComponent";
import FadeRightComponent from "./FadeRightComponent";

const DressCode = () => {
  const [visibleComponent, setVisibleComponent] = useState(0);

  const handleAnimationEnd = () => {
    setVisibleComponent((prev) => prev + 1);
  };

  return (
    <>
      <div style={{ marginTop: "50px" }}>
        <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
          <Typography
            style={{
              fontSize: "50px",
              textAlign: "center",
              maxWidth: "200px",
              margin: "auto",
              paddingBottom: "10px",
              fontFamily: "EnternalLight",
              fontStyle: "Italic",
            }}
            color="#5A86AD"
          >
            Dress Code
          </Typography>
        </FadeUpComponent>

        {visibleComponent >= 1 && (
          <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
            <Typography
              style={{
                fontSize: "18px",
                textAlign: "center",
                maxWidth: "600px",
                margin: "auto",
                fontStyle: "Italic",
              }}
              color="textSecondary"
            >
              Principal Sponsors
            </Typography>
          </FadeUpComponent>
        )}

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            maxWidth: "500px",
            margin: "auto",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {/* Full height container */}
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            {/* Flexbox for centering content inside the grid item */}
            <Paper
              style={{
                textAlign: "center",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              {visibleComponent >= 2 && (
                <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
                  <Typography
                    style={{ fontSize: "16px" }}
                    color="textSecondary"
                  >
                    <span style={{ fontWeight: "bold" }}>Gentlemen:</span>{" "}
                    Classic Barong, Black Pants and Black Shoes
                  </Typography>
                </FadeUpComponent>
              )}
            </Paper>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              style={{
                textAlign: "center",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              {visibleComponent >= 3 && (
                <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
                  <Typography
                    style={{ fontSize: "16px" }}
                    color="textSecondary"
                  >
                    <span style={{ fontWeight: "bold" }}>Ladies:</span>{" "}
                    Filipiniana
                  </Typography>
                </FadeUpComponent>
              )}
            </Paper>
          </Grid>

          {/* Image Grid item */}
          <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
            <Grid item>
              {visibleComponent >= 4 && (
                <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
                  <img
                    src={PrincipalPhoto}
                    alt="Dress Code Visual Aid"
                    style={{ maxWidth: "100%", height: "250px" }}
                  />
                </FadeUpComponent>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
            <Grid item>
              {visibleComponent >= 5 && (
                <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
                  <img
                    src={PrincipalSponsorsColors}
                    alt="Dress Code Visual Aid"
                    style={{ maxWidth: "200px" }}
                  />
                </FadeUpComponent>
              )}
            </Grid>
          </Grid>
        </Grid>
        {visibleComponent >= 6 && (
          <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
            <Divider
              sx={{
                margin: "auto",
                width: "350px",
                alignSelf: "center",
                marginTop: "20px",
              }}
            />
          </FadeUpComponent>
        )}
        {visibleComponent >= 7 && (
          <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
            <Typography
              style={{
                fontSize: "18px",
                textAlign: "center",
                maxWidth: "600px",
                margin: "auto",
                fontStyle: "Italic",
                marginTop: "20px",
              }}
              color="textSecondary"
            >
              Guests
            </Typography>
          </FadeUpComponent>
        )}

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            maxWidth: "500px",
            margin: "auto",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          {/* Full height container */}
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Paper
              style={{
                textAlign: "center",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              {visibleComponent >= 8 && (
                <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
                  <Typography
                    style={{ fontSize: "16px" }}
                    color="textSecondary"
                  >
                    <span style={{ fontWeight: "bold" }}>Gentlemen:</span>{" "}
                    Barong, Long Sleeves or Polo and Black Pants
                  </Typography>
                </FadeUpComponent>
              )}
            </Paper>
          </Grid>

          {/* Second Row */}

          <Grid
            item
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              padding: 0,
            }}
          >
            <Paper
              style={{
                textAlign: "center",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              {visibleComponent >= 9 && (
                <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
                  <Typography
                    style={{ fontSize: "16px" }}
                    color="textSecondary"
                  >
                    <span style={{ fontWeight: "bold" }}>Ladies:</span> Long or
                    Cocktail Dress or modern Maria Clara ensemble
                  </Typography>
                </FadeUpComponent>
              )}
            </Paper>
          </Grid>

          <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
            <Grid item>
              {visibleComponent >= 10 && (
                <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
                  <img
                    src={GuestDress}
                    alt="Dress Code Visual Aid"
                    style={{ maxWidth: "100%", height: "280px" }}
                  />
                </FadeUpComponent>
              )}
            </Grid>
          </Grid>
          {visibleComponent >= 11 && (
            <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
              <Typography
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  maxWidth: "600px",
                  margin: "auto",
                  fontStyle: "Italic",
                }}
                color="textSecondary"
              >
                We'd love to see you in these colors
              </Typography>
            </FadeUpComponent>
          )}

          <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
            <Grid item>
              {visibleComponent >= 12 && (
                <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
                  <img
                    src={GuestColors}
                    alt="Dress Code Visual Aid"
                    style={{ maxWidth: "150px" }}
                  />
                </FadeUpComponent>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DressCode;
