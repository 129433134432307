import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CardCarousel from "./Carousel";
import FadeUpComponent from "./FadeUpComponent";

const Gifts = () => {
  return (
    <>
      <FadeUpComponent>
        <div
          style={{
            marginTop: "70px",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "500px",
          }}
        >
          <Typography
            style={{
              fontSize: "50px",
              textAlign: "center",
              maxWidth: "200px",
              margin: "auto",
              paddingBottom: "10px",
              fontFamily: "EnternalLight",
              fontStyle: "Italic",
            }}
            color="#5A86AD"
          >
            Gifts
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              maxWidth: "500px",
              margin: "auto",
              marginBottom: "20px",
            }}
            color="textSecondary"
          >
            We're thrilled to have you with us as we celebrate! Just being there
            with us is the best gift of all. But if you're moved to give
            something more, a small donation to help build our future together
            would be deeply appreciated.
          </Typography>

          <div>
            <CardCarousel />
          </div>

          {/* <CardCarousel /> */}

          {/* <Stack style={{ marginTop: "20px" }}>
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              maxWidth: "600px",
              margin: "auto",
            }}
            color="textSecondary"
          >
            GCASH
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              maxWidth: "600px",
              margin: "auto",
            }}
            color="textSecondary"
          >
            Robert Jan Dela Cruz - 09175041217
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              maxWidth: "600px",
              margin: "auto",
              paddingBottom: "10px",
            }}
            color="textSecondary"
          >
            Laralyn Dabocol - 09566992192
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              maxWidth: "600px",
              margin: "auto",
              marginTop: "10px",
            }}
            color="textSecondary"
          >
            METROBANK
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              maxWidth: "600px",
              margin: "auto",
              paddingBottom: "10px",
            }}
            color="textSecondary"
          >
            2923-2926-58720
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              maxWidth: "600px",
              margin: "auto",
              marginTop: "10px",
            }}
            color="textSecondary"
          >
            PSBANK
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              maxWidth: "600px",
              margin: "auto",
              paddingBottom: "10px",
            }}
            color="textSecondary"
          >
            8881-1007-0363
          </Typography>
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              maxWidth: "600px",
              margin: "auto",
              marginTop: "10px",
            }}
            color="textSecondary"
          >
            BDO
          </Typography> */}
          {/* </Stack> */}
        </div>
      </FadeUpComponent>
    </>
  );
};

export default Gifts;
