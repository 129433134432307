// RSVPButton.js
import React, { useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "../css/RSVPButton.css"; // Importing the CSS file for styles

const RSVPButton = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    // Trigger the animation
    setIsClicked(true);

    // Optional: Reset the state after the animation completes (300ms)
    setTimeout(() => setIsClicked(false), 300);
  };

  return (
    <div className="rsvp-container">
      {/* <div className="wedding-info">
        <h1>Robert Jan & Laralyn</h1>
        <p>Our Wedding • June 15, 2024</p>
      </div> */}
      <div className="rsvp-button-container">
        {/* <button
          className={`rsvp-button ${isClicked ? "rsvp-button-clicked" : ""}`}
          onClick={handleClick}
        >
          <CalendarTodayIcon className="calendar-icon" /> RSVP
        </button> */}
      </div>
    </div>
  );
};

export default RSVPButton;
