// CenteredScreen.js
import React from 'react';
import '../css/Centered.css'; // Import the CSS for styling

const CenteredScreen = ({ children }) => {
  return (
    <div className="centered-screen">
      {children}
    </div>
  );
};

export default CenteredScreen;
