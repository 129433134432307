import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";

const FAQ = () => {
  const faqData = [
    {
      question: "Is RSVP required?",
      answer:
        "YES, please! Confirming your attendance will help us include you in our guest list. Please let us know before May 30, 2024.",
    },
    {
      question: 'May I invite a "PLUS 1" to the event?',
      answer:
        "As much as we love to accommodate all our friends and family, we only have a limited number of seats due to venue capacity restrictions. We have a strict guest list and we might not have any extra seats for your companion. Please understand that this event is strictly by invitation only. Names that are not found on the guest list we have provided to our Coordinator will not be allowed to enter.",
    },
    {
      question: "What if I RSVPed but could not go?",
      answer:
        "We would love to have you at our wedding, but we understand that there are some things that are out of our control. However, please let us know as soon as possible so we can reallocate your seats. Give us a toast from afar.",
    },
    {
      question: "What if I did not RSVP, can I still go?",
      answer:
        "Please check with us first. Unfortunately, we have a strict guest list. Kindly inform us if your schedule cleared up, so we can try to accommodate you. Should some seats free up, then we'll let you know as soon as we can. Please do not go unannounced, we might not have any available seat for you.",
    },
    {
      question: "What should I wear?",
      answer: (
        <Box sx={{ whiteSpace: "pre-line" }}>
          Your comfort is our priority. <br />
          We hope our wedding will be a fun excuse for you to dress up and be
          fab! <br />
          We kindly suggest that you join us in embracing the Filipiniana theme.
          <br />
          While there isn't a strict dress code, we encourage you to choose
          attire that reflects your comfort and confidence during this special
          celebration. <br /> Please refrain from wearing uncomfortable shoes.
          You may also want to bring hand-held fans especially in the church
          venue. <br /> The reception venue will be an event center so you may
          bring a shawl, coat or any cover-up. Do not wear slippers, flip-flops,
          denim, and out of color motif attires.
          <br />
          <br />
          <strong>FOR PRINCIPAL SPONSORS</strong>
          <br />
          Male - Classic Barong, Black Pants and Black Shoes
          <br />
          Female - Filipiniana
          <br />
          <br />
          <strong>FOR GUESTS</strong>
          <br />
          Gents - Classic Barong, Long Sleeves or Polo and Black or Brown Pants
          <br />
          Ladies - Long or Cocktail Dress or modern Maria Clara ensemble
        </Box>
      ),
    },
    {
      question: "What time should I arrive?",
      answer: (
        <Box sx={{ whiteSpace: "pre-line" }}>
          The ceremony will strictly start at 10:00 AM sharp, it's better to get
          to the church before then. <br /> We would appreciate your presence at
          the church as we exchange our vows. <br /> Kindly arrive on time and
          consider traffic through travel time. It would be nice to see you all
          while we are having our first walk down the aisle.
        </Box>
      ),
    },
    {
      question: "Is there a parking space for my car",
      answer: (
        <Box sx={{ whiteSpace: "pre-line" }}>
          Yes, there is a parking area available in the church and reception
          venue. <br />
          However, please remember it's on a first come, first served basis, so
          you might not want to be late.
        </Box>
      ),
    },
    {
      question: "Can I sit anywhere during the reception?",
      answer: (
        <Box sx={{ whiteSpace: "pre-line" }}>
          We planned a seating arrangement for our guests' convenience and group
          familiarity. Our coordinators will assist you in finding your
          designated seats. Feel free to ask them for assistance and they will
          gladly help you.
        </Box>
      ),
    },
    {
      question: "What should I do once I arrive at the reception venue?",
      answer: (
        <Box sx={{ whiteSpace: "pre-line" }}>
          You may use this time to: <br />
          <br />* Enjoy the snacks available in the reception venue
          <br />
          <br /> * Mingle with other guests <br />
          <br /> * Take note of your table number and seat <br />
          <br />* Roam around to check the venue and take photos
        </Box>
      ),
    },
    {
      question: "Can we bring our kids to the wedding?",
      answer: (
        <Box sx={{ whiteSpace: "pre-line" }}>
          <strong>This is a strict ADULT ONLY event.</strong> As much as we'd
          love to celebrate with your kids, the only kids invited to our wedding
          are the ones in the entourage and those we requested. We hope you
          understand and still enjoy the event.
        </Box>
      ),
    },
    {
      question:
        "Am I allowed to take pictures or videos during the church ceremony?",
      answer: (
        <Box sx={{ whiteSpace: "pre-line" }}>
          We hired professional photographers and videographers to capture our
          special moments so that you can sit back, relax and enjoy it with us.
          <br />
          Please keep your phones down and in silent mode during the
          processional and ceremony. Our beautiful images will be available
          after the wedding. <br />
          <br />
          <strong>
            Also, please keep the aisle clear during the bridal entrance.
          </strong>
          <br />
          <br /> Don't worry, once we're pronounced husband and wife, you can
          take all the photos you want after the ceremony and the entire
          reception, but please make way for the team that will cover our
          wedding.
        </Box>
      ),
    },
    {
      question:
        "How can I help the couple have a great time during their wedding?",
      answer: (
        <Box sx={{ whiteSpace: "pre-line" }}>
          * Pray with us that the weather will be in favor and for the continous
          blessings of our Lord as we enter the new chapter of our lives as
          husband and wife <br />
          <br /> * RSVP as early as your schedule is cleared. <br />
          <br /> * Dress appropriate and follow our wedding motif. <br />
          <br /> * Be on time. <br />
          <br /> * Follow seating arrangement in the reception. <br />
          <br /> * Stay until the end of the program. <br />
          <br /> * Avoid making comments which are unsolicited. <br />
          <br /> * Join the activities and enjoy!
        </Box>
      ),
    },
  ];

  return (
    <div style={{ maxWidth: "500px", margin: "auto", marginTop: "70px" }}>
      <Typography
        style={{
          fontSize: "50px",
          textAlign: "center",
          maxWidth: "500px",
          margin: "auto",
          paddingBottom: "10px",
          fontFamily: "EnternalLight",
          fontStyle: "Italic",
        }}
        color="#5A86AD"
      >
        Frequently Asked Questions
      </Typography>
      {faqData.map((faq, index) => (
        <Accordion key={index} sx={{ boxShadow: "none" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="textSecondary" style={{ fontWeight: "600" }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="textSecondary" style={{ fontStyle: "Italic" }}>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQ;
