import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FastfoodIcon from "@mui/icons-material/Fastfood"; // Import icons
import ChurchIcon from "@mui/icons-material/Church";
import LaptopMacIcon from "@mui/icons-material/LaptopMac"; // Import icons
import CelebrationIcon from "@mui/icons-material/Celebration";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import NightlifeIcon from "@mui/icons-material/Nightlife";
import RoomIcon from "@mui/icons-material/Room";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import Link from "@mui/material/Link";
import FadeUpComponent from "./FadeUpComponent";
import FadeLeftComponent from "./FadeLeftComponent";
import FadeRightComponent from "./FadeRightComponent";

const ScheduleTimeline = () => {
  const [visibleComponent, setVisibleComponent] = useState(0);

  const handleAnimationEnd = () => {
    setVisibleComponent((prev) => prev + 1);
  };

  const churchOnClick = () => {
    const mapUrl =
      "https://www.google.com/maps/place/St.+Bonaventure+Parish+Church+-+Daungan,+Mauban,+Quezon+(Diocese+of+Lucena)/@14.1904668,121.7283145,17z/data=!3m1!4b1!4m6!3m5!1s0x339807480a2a6e1f:0xefda785b3f79b03!8m2!3d14.1904668!4d121.7308948!16s%2Fg%2F11b6xk36ph?entry=ttu";
    window.location.href = mapUrl;
  };

  const receptionOnClick = () => {
    const mapUrl =
      "https://www.google.com/maps/place/Ikosan+Resort+Hotel/@14.1756438,121.7250503,16.22z/data=!4m9!3m8!1s0x339807fa6ddb0ad5:0x3ab74090b3b5d1e2!5m2!4m1!1i2!8m2!3d14.1764319!4d121.7249365!16s%2Fg%2F11rfr51j44?entry=ttu";
    window.location.href = mapUrl;
  };

  return (
    <>
      <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
        <div style={{ marginTop: "50px" }}>
          <Typography
            style={{
              fontSize: "50px",
              textAlign: "center",
              maxWidth: "200px",
              margin: "auto",
              paddingBottom: "10px",
              fontFamily: "EnternalLight",
              fontStyle: "Italic",
            }}
            color="#5A86AD"
          >
            Schedule
          </Typography>
        </div>
      </FadeUpComponent>

      <Timeline position="alternate">
        {visibleComponent >= 1 && (
          <FadeRightComponent onAnimationEnd={handleAnimationEnd}>
            <TimelineItem style={{ height: "100px" }}>
              <TimelineOppositeContent>
                <Link
                  href="#"
                  onClick={churchOnClick}
                  color="#5A86AD"
                  fontSize={"14px"}
                  fontWeight={"700"}
                  textAlign={"center"}
                >
                  <RoomIcon
                    style={{ color: "tomato", marginLeft: 0, fontSize: "14px" }}
                  />
                  St. Bonaventure Parish Church
                </Link>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <ChurchIcon /> {/* Custom icon */}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 800 }}
                  color="textSecondary"
                >
                  Ceremony
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  10:00 am
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </FadeRightComponent>
        )}
        {/* Second Row */}
        {visibleComponent >= 2 && (
          <FadeLeftComponent>
            <TimelineItem style={{ height: "100px" }}>
              <TimelineOppositeContent>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 800 }}
                  color="textSecondary"
                >
                  Pictorial
                  <Typography variant="body2" color="textSecondary">
                    11:00 am
                  </Typography>
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <LocalSeeIcon /> {/* Custom icon */}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent></TimelineContent>
            </TimelineItem>
          </FadeLeftComponent>
        )}

        {/* Third Row */}
        {visibleComponent >= 3 && (
          <FadeRightComponent>
            <TimelineItem style={{ height: "100px" }}>
              <TimelineOppositeContent>
                <Link
                  href="#"
                  onClick={receptionOnClick}
                  color="#5A86AD"
                  fontSize={"14px"}
                  fontWeight={"700"}
                  textAlign={"center"}
                >
                  <RoomIcon
                    style={{ color: "tomato", marginLeft: 0, fontSize: "14px" }}
                  />
                  Ikosan Resort Hotel
                </Link>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <NightlifeIcon /> {/* Custom icon */}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 800 }}
                  color="textSecondary"
                >
                  Reception
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  12:00 pm
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </FadeRightComponent>
        )}

        {/* fourth Row */}
        {visibleComponent >= 4 && (
          <FadeLeftComponent>
            <TimelineItem style={{ minHeight: "0" }}>
              <TimelineOppositeContent>
                <Typography
                  style={{ fontSize: "14px", fontWeight: 800 }}
                  color="textSecondary"
                >
                  Party
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  3:30 pm
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <CelebrationIcon /> {/* Custom icon */}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent></TimelineContent>
            </TimelineItem>
          </FadeLeftComponent>
        )}
      </Timeline>
    </>
  );
};

export default ScheduleTimeline;
