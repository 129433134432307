import React from "react";
import { useInView } from "react-intersection-observer";

const FadeUpComponent = ({ children, onAnimationEnd }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 1s ease-out, transform 1s ease-out",
      }}
      onTransitionEnd={inView ? onAnimationEnd : null} // Only call onAnimationEnd when inView becomes true
    >
      {children}
    </div>
  );
};

export default FadeUpComponent;
