import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Importing local images from the assets folder
import image1 from "../assets/GiftQR1.png";
import image2 from "../assets/GiftQR2.png";
import image3 from "../assets/GiftQR3.png";
import image4 from "../assets/GiftQR4.png";

const CardCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true, // Enables auto-play
    autoplaySpeed: 3000, // Time in ms between each auto-scroll (3000 ms = 3 seconds)
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Array of imported images
  const images = [
    image1,
    image2,
    image3,
    image4,
    // Add more image imports as needed
  ];

  const cardStyle = {
    padding: "10px",
    margin: "15px",
    boxSizing: "border-box",
  };

  const imageStyle = {
    height: "400px",
    display: "block",
    margin: "auto", // Ensures the image scales nicely within the card
  };

  return (
    <Slider
      {...settings}
      style={{ justifyContent: "center", maxWidth: "400px" }}
    >
      {images.map((src, index) => (
        <div style={cardStyle} key={index}>
          <img src={src} alt={`Slide ${index + 1}`} style={imageStyle} />
        </div>
      ))}
    </Slider>
  );
};

export default CardCarousel;
