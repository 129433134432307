import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import "../css/Countdown.css"; // Make sure to import the CSS file
import FadeUpComponent from "./FadeUpComponent";
import FadeRightComponent from "./FadeRightComponent";
import FadeLeftComponent from "./FadeLeftComponent";

const Countdown = () => {
  const calculateTimeLeft = () => {
    // Replace the date with the actual date of your event
    const eventDate = new Date("June 16, 2024 00:00:00");
    const difference = +eventDate - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const [visibleComponent, setVisibleComponent] = useState(0);

  const handleAnimationEnd = () => {
    setVisibleComponent((prev) => prev + 1);
  };

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <div className="countdown-circle" key={interval}>
      <div className="countdown-number">{timeLeft[interval]}</div>
      <div className="countdown-interval">{interval}</div>
    </div>
  ));

  return (
    <div
      className="countdown-container"
      style={{ textAlign: "center", marginTop: "70px" }}
    >
      <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
        <Typography
          style={{
            fontSize: "50px",
            textAlign: "center",
            margin: "auto",
            paddingBottom: "10px",
            fontFamily: "EnternalLight",
            lineHeight: "1",
            fontStyle: "Italic",
          }}
          color="#5A86AD"
        >
          Our big day is coming soon!
        </Typography>
      </FadeUpComponent>

      <FadeUpComponent onAnimationEnd={handleAnimationEnd}>
        <div className="countdown-timer" style={{ marginTop: "20px" }}>
          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>
      </FadeUpComponent>
    </div>
  );
};

export default Countdown;
